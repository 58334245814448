'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    margin-top: 0;\n    padding-top: 20px;\n  '], ['\n    margin-top: 0;\n    padding-top: 20px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    line-height: 44px;\n    font-size: 32px;\n    justify-content: space-between;\n    margin-bottom: 40px;\n  '], ['\n    line-height: 44px;\n    font-size: 32px;\n    justify-content: space-between;\n    margin-bottom: 40px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px;\n    position: fixed;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    border-top: 1px solid #151515;\n  '], ['\n    padding: 20px;\n    position: fixed;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    border-top: 1px solid #151515;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['padding-bottom: 120px;'], ['padding-bottom: 120px;']);

exports.default = RegionalFilter;

var _difference = require('ramda/src/difference');

var _difference2 = _interopRequireDefault(_difference);

var _intersection = require('ramda/src/intersection');

var _intersection2 = _interopRequireDefault(_intersection);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _RegionCheckboxGroupModal = require('../../components/RollsRoyce/RegionCheckboxGroupModal');

var _StyledIcon = require('../../components/StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _globalStyling = require('../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__Container',
  componentId: 'sc-120t589-0'
})(['display:flex;flex-direction:column;width:100%;position:relative;box-sizing:border-box;margin-top:8px;']);

var HeadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__HeadingWrapper',
  componentId: 'sc-120t589-1'
})(['']);

var SelectContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__SelectContainer',
  componentId: 'sc-120t589-2'
})(['display:flex;height:32px;width:100%;border-bottom:1px solid #151515;box-sizing:border-box;align-items:center;justify-content:space-between;margin-bottom:20px;']);

var SelectedText = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__SelectedText',
  componentId: 'sc-120t589-3'
})(['font-size:16px;color:#151515;font-family:\'RivieraNights-Regular\',\'Jost-Light\';font-weight:normal;text-transform:uppercase;font-size:16px;font-weight:400;line-height:26px;letter-spacing:0.5px;text-align:left;']);

var ChangeButton = (0, _styledComponents2.default)(SelectedText).withConfig({
  displayName: 'RegionalFilter__ChangeButton',
  componentId: 'sc-120t589-4'
})(['font-size:10px;cursor:pointer;text-transform:uppercase;font-weight:400;line-height:26px;letter-spacing:0.5px;text-align:right;']);

var RegionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__RegionContainer',
  componentId: 'sc-120t589-5'
})(['align-items:center;background-color:#fff;body:{overflow:hidden;}box-sizing:border-box;display:flex;flex-direction:column;height:100%;justify-content:center;left:0;margin-top:0;overflow-x:hidden;padding:200px 20px 20px;position:fixed;top:0;width:100%;z-index:1;', ''], _theme2.default.max.large(_templateObject));

var ModalHeader = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__ModalHeader',
  componentId: 'sc-120t589-6'
})(['align-items:center;border-bottom:1px solid #151515;color:#151515;display:flex;flex-direction:row;font-size:24px;font-weight:300;height:50px;justify-content:center;line-height:auto;letter-spacing:2px;margin-bottom:30px;padding:10px 0 20px;text-transform:uppercase;text-align:center;', ''], _theme2.default.min.medium(_templateObject2));

var Gutter = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__Gutter',
  componentId: 'sc-120t589-7'
})(['height:100%;margin:20px;max-width:1338px;width:100%;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__ButtonContainer',
  componentId: 'sc-120t589-8'
})(['display:flex;width:100%;align-items:center;justify-content:center;padding:0 20px 50px;background-color:#fff;box-sizing:border-box;', ''], _theme2.default.max.medium(_templateObject3));

var CloseIcon = _styledComponents2.default.img.withConfig({
  displayName: 'RegionalFilter__CloseIcon',
  componentId: 'sc-120t589-9'
})(['height:20px;cursor:pointer;', ''], _theme2.default.max.medium(_templateObject4));
var ListContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RegionalFilter__ListContainer',
  componentId: 'sc-120t589-10'
})(['', ''], _theme2.default.max.medium(_templateObject5));
function RegionalFilter(_ref) {
  var translations = _ref.translations,
      globalStyling = _ref.globalStyling,
      filtersStyling = _ref.filtersStyling,
      availableFilterValues = _ref.availableFilterValues,
      updateFilters = _ref.updateFilters,
      filterKey = _ref.filterKey,
      selectedData = _ref.selectedData;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      open = _useState2[0],
      toggleOpen = _useState2[1];

  var regions = availableFilterValues.regions;


  var renderIcon = function renderIcon(toggle, openIcon, closeIcon) {
    return toggle ? _react2.default.createElement(_StyledIcon2.default, { link: openIcon, width: 20, height: 20 }) : _react2.default.createElement(_StyledIcon2.default, { link: closeIcon, width: 20, height: 20 });
  };

  /* formatTitles: If all subregions of a region are selected. We return the region.
  Else we return a list of the selected subregions.
   flattenedTitles flattens formatTitles into a single and sorts it alphabetically.
  */

  var formatTitles = (0, _keys2.default)(regions).map(function (key) {
    var enabledSubregionsForRegion = regions[key].some(function (r) {
      return r.enabled;
    }) && regions[key].filter(function (r) {
      return r.enabled;
    }).map(function (sr) {
      return sr.name;
    });
    var selected = selectedData && selectedData.length > 0 ? selectedData : [];
    var enabledRegionKey = regions[key].some(function (r) {
      return r.enabled;
    }) && [key];

    return (0, _difference2.default)(enabledSubregionsForRegion, selected || []).length === 0 ? enabledRegionKey : (0, _intersection2.default)(enabledSubregionsForRegion, selected);
  }).filter(function (el) {
    return el.length > 0;
  });

  var regionalCheckboxes = (0, _keys2.default)(regions).sort().map(function (key) {
    return _react2.default.createElement(_RegionCheckboxGroupModal.RegionCheckboxGroup, {
      key: key,
      region: key,
      subregions: regions[key],
      filtersStyling: filtersStyling,
      updateFilters: updateFilters,
      selectedData: selectedData,
      filterKey: filterKey,
      renderIcon: renderIcon
    });
  });
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      HeadingWrapper,
      null,
      formatTitles[0] && _react2.default.createElement(
        _Global.HeadingThree,
        {
          styleOverride: function styleOverride() {
            return 'font-size: 10px;text-transform: uppercase;line-height: 26px;letter-spacing: 0.5px';
          }
        },
        translations.searchIn
      )
    ),
    _react2.default.createElement(
      SelectContainer,
      null,
      Array.isArray(selectedData) ? _react2.default.createElement(
        SelectedText,
        null,
        selectedData.length > 1 ? selectedData.length + ' ' + translations.selected : selectedData[0] + ' ' + translations.selected
      ) : _react2.default.createElement(
        SelectedText,
        null,
        translations.searchIn
      ),
      _react2.default.createElement(
        ChangeButton,
        { 'aria-label': 'button', onClick: function onClick() {
            return toggleOpen(!open);
          } },
        translations.change
      )
    ),
    open && _react2.default.createElement(
      RegionContainer,
      null,
      _react2.default.createElement(
        Gutter,
        null,
        _react2.default.createElement(
          ModalHeader,
          null,
          translations.chooseYourRegion,
          _react2.default.createElement(CloseIcon, {
            'aria-label': 'button',
            alt: 'close region modal',
            src: filtersStyling.closeIcon,
            onClick: function onClick() {
              return toggleOpen(!open);
            }
          })
        ),
        _react2.default.createElement(
          ListContainer,
          null,
          regionalCheckboxes
        ),
        _react2.default.createElement(
          ButtonContainer,
          null,
          _react2.default.createElement(_Global.Button, {
            buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                background-color: #6D30A7;\n                width: 250px;\n                ';
            },
            text: translations.closeButton,
            onClick: function onClick() {
              toggleOpen(!open);
            }
          })
        )
      )
    )
  );
}