'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdditionalFeatures = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding-top: 50px;\n  '], ['\n    padding-top: 50px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 50px 0;\n'], ['\n    padding: 50px 0;\n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 22px;\n    padding-bottom: 50px;\n'], ['\n    font-size: 22px;\n    padding-bottom: 50px;\n']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr;\n    column-gap: 12px;\n  '], ['\n    grid-template-columns: 1fr;\n    column-gap: 12px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    margin-bottom: 0;\n  '], ['\n    margin-bottom: 0;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _Global = require('../../../Global');

var _types = require('../types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FILTERED_CATEGORY_KEY = 'Hero Features';

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__Container',
  componentId: 'sc-ix97fq-0'
})(['display:flex;flex-direction:column;padding:100px 0;', ';', ';'], _theme2.default.max.large(_templateObject), _theme2.default.max.medium(_templateObject2));

var Header = (0, _styledComponents2.default)(_Global.HeadingTwo).withConfig({
  displayName: 'AdditionalFeatures__Header',
  componentId: 'sc-ix97fq-1'
})(['font-size:32px;letter-spacing:2px;text-transform:uppercase;width:100%;padding-bottom:10px;text-align:center;display:inline-block;padding-bottom:40px;', ';'], _theme2.default.max.large(_templateObject3));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__Item',
  componentId: 'sc-ix97fq-2'
})(['display:flex;min-height:40px;height:auto;align-items:center;border-bottom:0.5px solid #151515;padding:12px 0 0 10px;font-size:14px;line-height:20px;letter-spacing:0.5px;color:#151515;font-weight:300;', ';'], _theme2.default.max.large(_templateObject4));

var ItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__ItemContainer',
  componentId: 'sc-ix97fq-3'
})(['display:grid;grid-template-columns:1fr 1fr 1fr;column-gap:36px;margin-bottom:20px;', ';'], _theme2.default.max.large(_templateObject5));

var ItemTitle = _styledComponents2.default.div.withConfig({
  displayName: 'AdditionalFeatures__ItemTitle',
  componentId: 'sc-ix97fq-4'
})(['font-family:\'RivieraNights-Regular\';font-size:16px;font-weight:400;line-height:40px;letter-spacing:0.5px;width:100%;border-bottom:1.3px solid #151515;padding-left:2.5px;margin-bottom:8px;', ';'], _theme2.default.max.large(_templateObject6));

var createItemColumn = function createItemColumn(title, items) {
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      ItemTitle,
      null,
      title
    ),
    _react2.default.createElement(
      ItemContainer,
      null,
      items.map(function (name, index) {
        return _react2.default.createElement(
          Item,
          { key: 'additional-feature-' + index.toString() },
          name
        );
      })
    )
  );
};

var AdditionalFeatures = function AdditionalFeatures(_ref) {
  var translations = _ref.translations,
      vehicle = _ref.vehicle;

  var _ref2 = vehicle || {},
      _ref2$otherEquipment = _ref2.otherEquipment,
      otherEquipment = _ref2$otherEquipment === undefined ? [] : _ref2$otherEquipment;

  var filteredOtherEquipment = otherEquipment.filter(function (item) {
    return item.category !== FILTERED_CATEGORY_KEY && item.equipment.length > 0;
  });

  return _react2.default.createElement(
    _react.Fragment,
    null,
    filteredOtherEquipment.length > 0 && _react2.default.createElement(
      Container,
      null,
      translations.vdpOtherEquipmentHeader && _react2.default.createElement(
        Header,
        null,
        translations.vdpOtherEquipmentHeader
      ),
      filteredOtherEquipment.map(function (_ref3) {
        var category = _ref3.category,
            equipment = _ref3.equipment;
        return createItemColumn(category, equipment);
      })
    )
  );
};

exports.AdditionalFeatures = AdditionalFeatures;