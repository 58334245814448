'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    flex-direction: column;\n  '], ['\n    display: flex;\n    flex-direction: column;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0;\n  '], ['\n    margin: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    justify-content: space-between;\n    margin: 0;\n  '], ['\n    display: flex;\n    justify-content: space-between;\n    margin: 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      flex: 0 0 48%;\n  '], ['\n      flex: 0 0 48%;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    ', ';\n  '], ['\n    ', ';\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n  display: flex;\n  align-items: flex-end;\n  flex-wrap: wrap;\n  width: 100%;\n  margin-top: 20px;\n  margin-top: ', ';\n  ', ';\n  '], ['\n  display: flex;\n  align-items: flex-end;\n  flex-wrap: wrap;\n  width: 100%;\n  margin-top: 20px;\n  margin-top: ', ';\n  ', ';\n  ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 5%;\n    padding-bottom: 20px;\n  '], ['\n    margin: 0 5%;\n    padding-bottom: 20px;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    width: ', ';\n    max-width: 1000px;\n    flex-direction: row;\n    margin: 10px auto;\n  '], ['\n    width: ', ';\n    max-width: 1000px;\n    flex-direction: row;\n    margin: 10px auto;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    gap: 10px;\n    flex-direction: row;\n    align-items: center;\n  '], ['\n    gap: 10px;\n    flex-direction: row;\n    align-items: center;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: column;\n    justify-content: space-even;\n    margin-top: ', '\n  '], ['\n    flex-direction: column;\n    justify-content: space-even;\n    margin-top: ', '\n  ']);

exports.default = GalleryAndMyDeal;

var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _helpers = require('../shared/currencyConversion/helpers');

var _data = require('../shared/financeCalculator/data');

var _globalDrawers = require('../shared/globalDrawers');

var _dates = require('../shared/localisation/dates');

var _theme = require('../theme');

var _theme2 = _interopRequireDefault(_theme);

var _types = require('../types');

var _vehicleTypes = require('../types/vehicleTypes');

var _FooterActionBanner = require('./FooterActionBanner');

var _FooterActionBanner2 = _interopRequireDefault(_FooterActionBanner);

var _Global = require('./Global');

var _Phone = require('./JaguarIcons/Global/Phone');

var _Phone2 = _interopRequireDefault(_Phone);

var _VdpGallery = require('./VdpGallery');

var _VdpGallery2 = _interopRequireDefault(_VdpGallery);

var _VdpMyDeal = require('./VdpMyDeal');

var _VdpMyDeal2 = _interopRequireDefault(_VdpMyDeal);

var _VehicleInfoBanner = require('./VehicleInfoBanner');

var _VehicleInfoBanner2 = _interopRequireDefault(_VehicleInfoBanner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'GalleryDealContainer'
}).withConfig({
  displayName: 'VdpGalleryAndMyDeal__Container',
  componentId: 'sc-1o9h8cs-0'
})(['display:flex;justify-content:center;align-items:stretch;padding-top:', ';', ';'], function (props) {
  return props.showDeal ? '0px' : '30px';
}, _theme2.default.max.large(_templateObject));
var BottomText = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__BottomText',
  componentId: 'sc-1o9h8cs-1'
})(['padding:24px 48px;color:#7e7e7e;font-size:13px;line-height:16px;text-align:center;margin:10px auto;box-sizing:border-box;', ';', ';', ';', ';'], function (_ref) {
  var width = _ref.width;
  return width && 'width: ' + width + '%;';
}, function (_ref2) {
  var showBorders = _ref2.showBorders;
  return showBorders && 'border-top: 1px solid #9B9B9B; border-bottom: 1px solid #9B9B9B;';
}, function (_ref3) {
  var bottomTextFont = _ref3.bottomTextFont;
  return bottomTextFont && (0, _Global.fontStyleOverride)(bottomTextFont);
}, _theme2.default.max.large(_templateObject2));

var ToggleMobile = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__ToggleMobile',
  componentId: 'sc-1o9h8cs-2'
})(['display:flex;justify-content:space-between;margin-bottom:10px;', ';'], _theme2.default.max.large(_templateObject2));

var ActionButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__ActionButtonsWrapper',
  componentId: 'sc-1o9h8cs-3'
})(['width:100%;margin:10px 20;display:flex;justify-content:space-between;', ';', ';'], function (_ref4) {
  var grow = _ref4.grow;
  return grow && 'flex-grow: 1;';
}, _theme2.default.min.large(_templateObject3));

var ActionButtonsBox = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__ActionButtonsBox',
  componentId: 'sc-1o9h8cs-4'
})(['width:100%;margin:20px 0;', ';'], _theme2.default.min.large(_templateObject4));

var ActionButtonsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__ActionButtonsContainer',
  componentId: 'sc-1o9h8cs-5'
})(['width:49%;', ';'], function (_ref5) {
  var margin = _ref5.margin;
  return margin && margin.marginTop && 'margin-top: ' + margin.marginTop + 'px';
});

var CtaButtonsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__CtaButtonsContainer',
  componentId: 'sc-1o9h8cs-6'
})(['width:100%;display:flex;flex-wrap:wrap;justify-content:space-between;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__ButtonContainer',
  componentId: 'sc-1o9h8cs-7'
})(['display:flex;flex:1 0 100%;margin-bottom:15px;box-sizing:border-box;', ';', ';'], _theme2.default.min.large(_templateObject5), _theme2.default.max.large(_templateObject6, function (_ref6) {
  var order = _ref6.order;
  return order && 'order: ' + order;
}));

var PhoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'PhoneLink'
}).withConfig({
  displayName: 'VdpGalleryAndMyDeal__PhoneLink',
  componentId: 'sc-1o9h8cs-8'
})(['text-decoration:none;']);

var MyDealContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__MyDealContainer',
  componentId: 'sc-1o9h8cs-9'
})(['display:flex;flex:1 0 0%;flex-direction:column;padding:', '};', ';'], function (_ref7) {
  var containerPadding = _ref7.containerPadding,
      containerPaddingRTL = _ref7.containerPaddingRTL,
      direction = _ref7.direction;
  return direction === 'rtl' ? containerPaddingRTL : '' + (containerPadding || '20px 2% 0 2%');
}, _theme2.default.max.large(_templateObject7));

var MyDealButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'MyDealButtonContainer'
}).withConfig({
  displayName: 'VdpGalleryAndMyDeal__MyDealButtonContainer',
  componentId: 'sc-1o9h8cs-10'
})(['', ';', ';'], _theme2.default.min.large(_templateObject8, function (_ref8) {
  var styles = _ref8.styles;
  return styles.marginTop ? styles.marginTop + 'px' : '20px';
}, function (_ref9) {
  var styles = _ref9.styles;
  return styles.paddingBottom && 'padding-bottom: ' + styles.paddingBottom + 'px';
}), _theme2.default.max.large(_templateObject9));

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'PhoneIconWrapper'
}).withConfig({
  displayName: 'VdpGalleryAndMyDeal__IconWrapper',
  componentId: 'sc-1o9h8cs-11'
})(['display:flex;align-items:center;justify-content:center;']);

var BottomInfoSectionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__BottomInfoSectionWrapper',
  componentId: 'sc-1o9h8cs-12'
})(['display:flex;justify-content:space-between;flex-direction:column;width:100%;padding:0 5%;box-sizing:border-box;', ';', ';'], _theme2.default.min.medium(_templateObject10, function (_ref10) {
  var galleryWidth = _ref10.galleryWidth;
  return galleryWidth ? galleryWidth + '%;' : '60%;';
}), _theme2.default.min.large(_templateObject7));

var BottomInfoActionButtons = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__BottomInfoActionButtons',
  componentId: 'sc-1o9h8cs-13'
})(['display:flex;flex-direction:column;gap:24px;', ';'], _theme2.default.min.medium(_templateObject11));

var BottomInfoPricingSection = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__BottomInfoPricingSection',
  componentId: 'sc-1o9h8cs-14'
})(['display:flex;flex-direction:column;justify-content:space-between;margin:20px 0;', ';'], _theme2.default.min.medium(_templateObject3));

var AvailabilityLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__AvailabilityLabel',
  componentId: 'sc-1o9h8cs-15'
})(['', ';', ';', ' ', ' ', ''], function (_ref11) {
  var bold = _ref11.bold;
  return bold && 'font-weight: bold;';
}, function (_ref12) {
  var size = _ref12.size;
  return 'font-size: ' + size + 'px;';
}, function (_ref13) {
  var dateTextColor = _ref13.dateTextColor;
  return dateTextColor && 'color: ' + dateTextColor + ';';
}, function (_ref14) {
  var topPadding = _ref14.topPadding;
  return topPadding && 'padding-top: ' + topPadding + 'px;';
}, function (_ref15) {
  var vdpPadding = _ref15.vdpPadding;
  return vdpPadding && 'padding: ' + vdpPadding + ';';
});

var AvailabilityLabelWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__AvailabilityLabelWrapper',
  componentId: 'sc-1o9h8cs-16'
})(['display:flex;align-items:baseline;']);

var ApprovedImage = _styledComponents2.default.img.withConfig({
  displayName: 'VdpGalleryAndMyDeal__ApprovedImage',
  componentId: 'sc-1o9h8cs-17'
})(['align-self:center;height:42px;margin-bottom:10px;margin-top:5px;']);

var StyledContactLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__StyledContactLabel',
  componentId: 'sc-1o9h8cs-18'
})(['margin-left:\'8px\';overflow:hidden;text-overflow:ellipsis;']);

var CarBasePriceContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__CarBasePriceContainer',
  componentId: 'sc-1o9h8cs-19'
})(['display:flex;justify-content:space-between;']);

var OtherAmountContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__OtherAmountContainer',
  componentId: 'sc-1o9h8cs-20'
})(['display:flex;justify-content:space-between;']);

var DealerPriceWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VdpGalleryAndMyDeal__DealerPriceWrapper',
  componentId: 'sc-1o9h8cs-21'
})(['display:flex;justify-content:', ';gap:5px;', ';'], function (_ref16) {
  var hasCarPass = _ref16.hasCarPass;
  return hasCarPass ? 'space-between' : 'center';
}, _theme2.default.min.medium(_templateObject12, function (_ref17) {
  var hasCarPass = _ref17.hasCarPass;
  return hasCarPass ? '0px' : '15px';
}));

var CarPassLink = _styledComponents2.default.a.withConfig({
  displayName: 'VdpGalleryAndMyDeal__CarPassLink',
  componentId: 'sc-1o9h8cs-22'
})(['text-decoration:none;margin:0px;padding:0px;']);

var CarPassLogo = _styledComponents2.default.img.withConfig({
  displayName: 'VdpGalleryAndMyDeal__CarPassLogo',
  componentId: 'sc-1o9h8cs-23'
})(['object-fit:cover;width:100px;height:23px;']);

function labelWithIcon(label, iconColour, Icon) {
  return _react2.default.createElement(
    IconWrapper,
    null,
    _react2.default.createElement(Icon, { width: '2em', height: '2em', colour: iconColour }),
    _react2.default.createElement(
      StyledContactLabel,
      null,
      label
    )
  );
}

var ContactButtons = function ContactButtons(_ref18) {
  var makeAnEnquiry = _ref18.makeAnEnquiry,
      makeATestDriveEnquiry = _ref18.makeATestDriveEnquiry,
      makeAReserveEnquiry = _ref18.makeAReserveEnquiry,
      makeAnOnDemandEnquiry = _ref18.makeAnOnDemandEnquiry,
      iconColour = _ref18.iconColour,
      translations = _ref18.translations,
      vehicleInfo = _ref18.vehicleInfo,
      grow = _ref18.grow,
      showContactbuttons = _ref18.showContactbuttons,
      showContactButton = _ref18.showContactButton,
      showOnDemand = _ref18.showOnDemand,
      buttonStyles = _ref18.buttonStyles,
      direction = _ref18.direction,
      onDemandBtn = _ref18.onDemandBtn,
      showReserveButton = _ref18.showReserveButton,
      showTestDriveButton = _ref18.showTestDriveButton,
      makeAnInsuranceEnquiry = _ref18.makeAnInsuranceEnquiry;
  return _react2.default.createElement(
    _react.Fragment,
    null,
    showContactbuttons ? _react2.default.createElement(
      ActionButtonsBox,
      { grow: grow },
      _react2.default.createElement(
        CtaButtonsContainer,
        null,
        showTestDriveButton && _react2.default.createElement(
          ButtonContainer,
          { order: 1 },
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'vdp-gallery-and-mydeal-contact-us-button',
            text: translations.vdpTestDriveButtonLabel,
            onClick: makeATestDriveEnquiry,
            vehicleInfo: vehicleInfo,
            buttonStyle: buttonStyles,
            styleOverride: function styleOverride() {
              return '\n                  width: 100%;\n                ';
            }
          })
        ),
        showReserveButton && _react2.default.createElement(
          ButtonContainer,
          { order: 2 },
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'contact-us-button',
            text: translations.vdpReserveButtonLabel,
            onClick: makeAReserveEnquiry,
            buttonStyle: buttonStyles,
            styleOverride: function styleOverride() {
              return 'width: 100%;';
            }
          })
        ),
        showContactButton && _react2.default.createElement(
          ButtonContainer,
          { order: 3 },
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'contact-us-button',
            text: translations.vdpContactUs,
            onClick: makeAnEnquiry,
            buttonStyle: buttonStyles,
            styleOverride: function styleOverride() {
              return '\n                  width: 100%;\n                ';
            }
          })
        ),
        makeAnInsuranceEnquiry && _react2.default.createElement(
          ButtonContainer,
          null,
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'contact-us-button',
            text: translations.insuranceFormCta,
            onClick: makeAnInsuranceEnquiry,
            buttonStyle: buttonStyles,
            order: 4,
            styleOverride: function styleOverride() {
              return '\n                  width: 100%;\n                ';
            }
          })
        )
      )
    ) : _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        ActionButtonsWrapper,
        null,
        _react2.default.createElement(
          ActionButtonsContainer,
          null,
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'contact-us-button',
            applyStyle: 'primary',
            styleOverride: function styleOverride() {
              return '\n                  width: 100%;\n                ';
            },
            text: translations.vdpContactUs,
            onClick: makeAnEnquiry,
            buttonStyle: buttonStyles
          })
        ),
        _react2.default.createElement(
          ActionButtonsContainer,
          null,
          _react2.default.createElement(
            PhoneLink,
            { href: 'tel:' + vehicleInfo.retailerInformation.phone },
            _react2.default.createElement(_Global.Button, {
              'data-cy': 'phone-number-button',
              applyStyle: 'secondary',
              styleOverride: function styleOverride() {
                return '\n                    width: 100%;\n                    ' + (direction === 'rtl' && 'direction: ltr') + '\n                  ';
              },
              text: labelWithIcon(vehicleInfo.retailerInformation.phone, iconColour, _Phone2.default),
              buttonStyle: 'ButtonStyleBTT'
            })
          )
        )
      ),
      showOnDemand && _react2.default.createElement(
        ActionButtonsWrapper,
        null,
        _react2.default.createElement(
          ActionButtonsContainer,
          { margin: onDemandBtn },
          _react2.default.createElement(_Global.Button, {
            'data-cy': 'contact-us-button',
            text: translations.vdpOnDemand,
            onClick: makeAnOnDemandEnquiry,
            buttonStyle: buttonStyles,
            styleOverride: function styleOverride() {
              return '\n                width: 100%;\n              ';
            }
          })
        )
      )
    )
  );
};

var JapaneseDealerPrice = function JapaneseDealerPrice(_ref19) {
  var price = _ref19.price,
      total = _ref19.total;
  return _react2.default.createElement(
    'div',
    { style: { display: 'flex', justifyContent: 'space-between' } },
    _react2.default.createElement(
      'div',
      null,
      total
    ),
    _react2.default.createElement(
      'div',
      { style: { fontWeight: '700' } },
      price
    )
  );
};

var CarPassComponent = function CarPassComponent(_ref20) {
  var url = _ref20.url;
  return _react2.default.createElement(
    CarPassLink,
    { href: url, target: '_blank' },
    _react2.default.createElement(CarPassLogo, {
      src: 'https://res.cloudinary.com/motortrak/f_auto/v1722249867/locator/generic/carpass/car-pass.png',
      alt: 'Car pass Logo'
    })
  );
};

var DealerPrice = function DealerPrice(_ref21) {
  var price = _ref21.price,
      carPassUrl = _ref21.carPassUrl;
  return _react2.default.createElement(
    DealerPriceWrapper,
    { hasCarPass: carPassUrl },
    _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n        margin: 0;\n        font-size: 20px;\n      ';
        }
      },
      price
    ),
    carPassUrl && _react2.default.createElement(CarPassComponent, { url: carPassUrl })
  );
};

var BottomInfoSection = function BottomInfoSection(_ref22) {
  var price = _ref22.price,
      makeAnEnquiry = _ref22.makeAnEnquiry,
      iconColour = _ref22.iconColour,
      translations = _ref22.translations,
      vehicleInfo = _ref22.vehicleInfo,
      approvedImage = _ref22.approvedImage,
      galleryWidth = _ref22.galleryWidth,
      buttonStyle = _ref22.buttonStyle,
      showButton = _ref22.showButton,
      formattedDate = _ref22.formattedDate,
      availabilityFont = _ref22.availabilityFont,
      isRemarketingSite = _ref22.isRemarketingSite,
      showFinance = _ref22.showFinance;

  var dispatch = (0, _reactRedux.useDispatch)();

  var isJapaneseDealer = vehicleInfo.retailerInformation.countryCode === 'JP' && !vehicleInfo.priceSpecial && vehicleInfo.basePrice && vehicleInfo.expenses;

  var basePrice = (0, _helpers.handleVehiclePrice)(vehicleInfo.basePrice, {
    currencyType: vehicleInfo.price.currency,
    locale: vehicleInfo.locale.replace('_', '-')
  });
  var otherAmount = (0, _helpers.handleVehiclePrice)(vehicleInfo.expenses, {
    currencyType: vehicleInfo.price.currency,
    locale: vehicleInfo.locale.replace('_', '-')
  });
  var shouldShowFinance = showFinance && vehicleInfo.modelYear > 0 && vehicleInfo.lfsModelCode && ['CA', 'US'].includes(vehicleInfo.retailerInformation.countryCode) && vehicleInfo.price.value > 0;

  return _react2.default.createElement(
    BottomInfoSectionWrapper,
    {
      galleryWidth: galleryWidth,
      isRemarketingSite: isRemarketingSite
    },
    _react2.default.createElement(
      BottomInfoPricingSection,
      null,
      isJapaneseDealer ? _react2.default.createElement(JapaneseDealerPrice, { price: price, total: translations.total }) : _react2.default.createElement(DealerPrice, { price: price, carPassUrl: vehicleInfo.carpassUrl }),
      isJapaneseDealer && _react2.default.createElement(
        'div',
        {
          style: {
            fontSize: '12px',
            borderTop: '1px solid rgb(213, 213, 213)',
            marginTop: '5px',
            paddingTop: '5px'
          }
        },
        _react2.default.createElement(
          CarBasePriceContainer,
          null,
          _react2.default.createElement(
            'div',
            { style: { paddingRight: '5px' } },
            translations.carBasePrice
          ),
          _react2.default.createElement(
            'div',
            { style: { fontSize: '16px', fontWeight: '700' } },
            basePrice
          )
        ),
        _react2.default.createElement(
          OtherAmountContainer,
          null,
          _react2.default.createElement(
            'div',
            { style: { paddingRight: '5px' } },
            translations.otherAmount
          ),
          _react2.default.createElement(
            'div',
            { style: { fontSize: '16px', fontWeight: '700' } },
            otherAmount
          )
        )
      ),
      vehicleInfo.sectionSalesTaxDisclaimer === 1 && _react2.default.createElement(
        'div',
        {
          style: { fontSize: '12px', color: '#9B9B9B', textAlign: 'left' }
        },
        translations.differentialTaxation
      ),
      formattedDate && availabilityFont && _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          AvailabilityLabelWrapper,
          null,
          _react2.default.createElement(
            AvailabilityLabel,
            {
              size: availabilityFont.dateSizeText,
              vdpPadding: availabilityFont.vdpPadding
            },
            translations.availabilityDate
          ),
          _react2.default.createElement(
            AvailabilityLabel,
            {
              bold: true,
              size: availabilityFont.dateSize,
              topPadding: availabilityFont.dateTopPadding
            },
            formattedDate
          )
        ),
        _react2.default.createElement(
          AvailabilityLabel,
          {
            size: availabilityFont.disclaimerSize,
            topPadding: availabilityFont.disclaimerTextPadding,
            dateTextColor: availabilityFont.dateTextColor
          },
          translations.availabilityDateDisclaimerText
        )
      ),
      vehicleInfo.priceExcludesVat > 0 && _react2.default.createElement(
        'div',
        {
          style: { fontSize: '12px', color: '#9B9B9B', textAlign: 'left' }
        },
        translations.priceExcludesVat
      ),
      vehicleInfo.vatQualifyingVehicle > 0 && _react2.default.createElement(
        'div',
        {
          style: { fontSize: '12px', color: '#9B9B9B', textAlign: 'left' }
        },
        translations.vatQualifyingVehicle
      )
    ),
    !isRemarketingSite && _react2.default.createElement(
      BottomInfoActionButtons,
      null,
      vehicleInfo.approved && _react2.default.createElement(ApprovedImage, {
        src: approvedImage,
        alt: translations.selezioneLogoAlternativeText || ''
      }),
      shouldShowFinance && _react2.default.createElement(_Global.Button, {
        applyStyle: 'primary',
        buttonStyle: buttonStyle,
        text: translations.financeButtonLabel,
        onClick: function onClick() {
          return dispatch(_globalDrawers.actions.toggleFinanceDrawer(vehicleInfo));
        },
        styleOverride: function styleOverride() {
          return 'min-width: 150px;';
        }
      }),
      showButton && _react2.default.createElement(_Global.Button, {
        applyStyle: 'primary',
        buttonStyle: buttonStyle,
        text: translations.vdpContactUs,
        onClick: makeAnEnquiry,
        styleOverride: function styleOverride() {
          return 'min-width: 150px;';
        }
      }),
      vehicleInfo.retailerInformation.phone && _react2.default.createElement(
        PhoneLink,
        { href: 'tel:' + vehicleInfo.retailerInformation.phone },
        _react2.default.createElement(_Global.Button, {
          'data-cy': 'phone-number-button',
          applyStyle: 'secondary',
          text: labelWithIcon(vehicleInfo.retailerInformation.phone, iconColour, _Phone2.default),
          buttonStyle: 'ButtonStyle1',
          styleOverride: function styleOverride() {
            return 'min-width: 150px;';
          }
        })
      )
    )
  );
};

var financeDataToDisplay = function financeDataToDisplay(props, financeLoaded) {
  var globalFinance = {
    translations: (0, _extends3.default)({}, props.financeTranslations, {
      searchResultsNotAvailableLabel: props.translations.searchResultsNotAvailableLabel
    }),
    locale: props.locale,
    financeHeadingDisplay: props.financeHeadingDisplay
  };

  return (0, _data.getFinanceHeaderDisplayList)(props.finance || {
    vehiclePrice: props.vehicleInfo.price.value,
    currency: props.vehicleInfo.price.currency
  }, globalFinance, financeLoaded);
};

function GalleryAndMyDeal(props) {
  var rightButton = function rightButton() {
    return _react2.default.createElement(_Global.Button, {
      onClick: props.reserveThisVehicle,
      text: props.translations.vdpReserveButtonLabel,
      applyStyle: 'primary',
      buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle
    });
  };
  var leftButton = function leftButton() {
    return _react2.default.createElement(_Global.Button, {
      onClick: props.reserveThisVehicle,
      text: props.translations.vdpTestDriveButtonLabel,
      applyStyle: 'primary',
      buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle
    });
  };
  var hasFinance = !!props.vehicleInfo.finance;
  var financeLoaded = props.vehicleInfo.financeLoaded;

  var showSalesTaxDisclaimer = props.config.showSectionSalesTaxDisclaimer && !!props.vehicleInfo.sectionSalesTaxDisclaimer;

  var price = (0, _helpers.handleVehiclePrice)(!showSalesTaxDisclaimer ? props.vehicleInfo.price.value : props.vehicleInfo.netPrice, {
    currencyType: props.vehicleInfo.price.currency,
    locale: props.locale.replace('_', '-'),
    handleZeroPriceAsText: true,
    priceSpecial: props.vehicleInfo.priceSpecial,
    translationsSold: props.translations.sold,
    vehiclePrice: props.vehicleInfo.price.value,
    zeroPriceText: props.config.translations.zeroPriceText
  });

  var showButton = !props.config.hideContactButton || !RegExp(props.config.hideContactButton.value).test((0, _path2.default)(props.config.hideContactButton.selectorProp.split('.'), props.vehicleInfo));

  var formattedDate = props.vehicleInfo.availabilityDate && (0, _dates.localeFormattedDate)(props.vehicleInfo.availabilityDate, props.locale, {
    month: 'long',
    year: 'numeric'
  });

  return _react2.default.createElement(
    'div',
    null,
    props.showStickyBanner && !props.featureFlags.hideVdpStickyBanner && _react2.default.createElement(_VehicleInfoBanner2.default, {
      iconColour: props.iconColour,
      borderColour: props.borderColour,
      featureFlags: props.featureFlags,
      translations: props.translations,
      imageSrc: props.vehicleImages.slides.length > 0 ? props.vehicleImages.slides[0].image : props.placeholdingImage,
      title: props.hideYear ? props.vehicleInfo.description : props.vehicleInfo.registration + ' ' + props.vehicleInfo.description,
      vehicleDetail: (0, _VdpGallery.formattedVehicleDescription)(props.subHeaderType, props.vehicleInfo, null, props.config.subtitleData),
      dealerTelephone: props.vehicleInfo.retailerInformation.phone,
      info: hasFinance && financeLoaded ? financeDataToDisplay(props, financeLoaded).map(function (fd) {
        return [fd.value, fd.label];
      }) : [[price, props.financeTranslations && props.financeTranslations.financeVehiclePrice || props.translations.searchResultsPriceLabel]],
      onReserveButtonClick: props.reserveThisVehicle,
      onTestDriveButtonClick: props.bookTestDrive,
      buttonTheme: props.buttonTheme,
      contentWidth: props.globalStyling.contentWidth,
      stickyBannerStyling: props.stickyBannerStyling,
      globalStyling: props.globalStyling
    }),
    (props.featureFlags.testDrive || props.featureFlags.reserve) && _react2.default.createElement(_FooterActionBanner2.default, {
      leftButton: props.featureFlags.testDrive && leftButton,
      rightButton: props.featureFlags.reserve && rightButton
    }),
    _react2.default.createElement(
      Container,
      { showDeal: props.showDeal, 'data-cy': 'vdp-gallery-and-mydeal' },
      _react2.default.createElement(_VdpGallery2.default, {
        shortListedIconColour: props.shortListedIconColour,
        compareIconColour: props.compareIconColour,
        translations: props.translations,
        vehicleInfo: props.vehicleInfo,
        vehicleImages: props.vehicleImages,
        compareVehicle: props.compareVehicle,
        shortlistVehicle: props.shortlistVehicle,
        placeholdingImage: props.placeholdingImage,
        subHeaderType: props.subHeaderType,
        vehicleGalleryArrowFont: props.vehicleGalleryArrowFont,
        vehicleHeadingFont: props.vehicleHeadingFont,
        vehicleHeadingColour: props.vehicleHeadingColour,
        vehicleHeadingPadding: props.vehicleHeadingPadding,
        vehicleHeadingMargin: props.vehicleHeadingMargin,
        heartMobileNoPadding: props.heartMobileNoPadding,
        rotateGallery: props.rotateGallery,
        showShareButton: props.showShareButton,
        galleryWidth: props.galleryWidth,
        socialShare: props.socialShare,
        config: props.config,
        imageCarousel: props.imageCarousel,
        globalStyling: props.globalStyling
      }),
      props.showDeal && _react2.default.createElement(
        MyDealContainer,
        {
          containerPadding: props.financePricingTable && props.financePricingTable.containerPadding,
          containerPaddingRTL: props.financePricingTable && props.financePricingTable.containerPaddingRTL,
          direction: props.globalStyling.direction
        },
        _react2.default.createElement(_VdpMyDeal2.default, {
          vehicleInfo: props.vehicleInfo,
          translations: props.translations,
          downpaymentRange: props.downpaymentRange,
          termsRange: props.termsRange,
          requestFinanceProductUpdate: props.requestFinanceProductUpdate,
          finance: props.finance,
          updateMyDeal: props.updateMyDeal,
          updateMyDealWithPartExchange: props.updateMyDealWithPartExchange,
          saveMyDeal: props.saveMyDeal,
          printMyDeal: props.printMyDeal,
          financeProductExample: props.financeProductExample,
          buttonTheme: props.buttonSecondaryTheme,
          iconColour: props.iconColour,
          getValuationMakes: props.getValuationMakes,
          valuationMakes: props.valuationMakes,
          getValuationModels: props.getValuationModels,
          valuationModels: props.valuationModels,
          getValuationVersions: props.getValuationVersions,
          valuationVersions: props.valuationVersions,
          getValuationYears: props.getValuationYears,
          valuationYears: props.valuationYears,
          getValuationMonths: props.getValuationMonths,
          valuationMonths: props.valuationMonths,
          getVehicleValuation: props.getVehicleValuation,
          vehicleValuation: props.vehicleValuation,
          vehicleValuationRequestLimit: props.vehicleValuationRequestLimit,
          editVehicleValuation: props.editVehicleValuation,
          cancelEditVehicleValuation: props.cancelEditVehicleValuation,
          valuationError: props.valuationError,
          featureFlags: props.featureFlags,
          globalStyling: props.globalStyling,
          hasFinance: hasFinance,
          financeLoaded: financeLoaded,
          locale: props.locale,
          financeProducts: props.financeProducts,
          resetFinanceProduct: props.resetFinanceProduct,
          context: props.context,
          financeCellData: financeDataToDisplay(props, financeLoaded),
          printing: props.printing,
          financeTranslations: props.financeTranslations,
          showFinanceDescriptions: props.showFinanceDescriptions,
          financePricingTable: props.financePricingTable,
          featuredSpec: props.featuredSpec,
          hideActionButtons: props.hideActionButtons,
          badgeWithStripUrl: props.badgeWithStripUrl,
          badgeWithoutStripUrl: props.badgeWithoutStripUrl,
          warrantyBadgeMonths: props.warrantyBadgeMonths,
          warrantyBadgeMaxMileage: props.warrantyBadgeMaxMileage,
          featureSpecificationData: props.featureSpecificationData,
          convertedKwToHp: props.config && props.config.convertedKwToHp,
          config: props.config
        }),
        _react2.default.createElement(_reactWaypoint2.default, {
          onPositionChange: function onPositionChange(_ref23) {
            var currentPosition = _ref23.currentPosition;
            return props.toggleStickyBanner(currentPosition === 'above');
          }
        }),
        _react2.default.createElement(
          MyDealButtonContainer,
          { styles: props.myDealButtonContainerStyles },
          _react2.default.createElement(
            ToggleMobile,
            null,
            props.featureFlags.testDrive && _react2.default.createElement(
              'div',
              { style: { width: '49%' } },
              _react2.default.createElement(_Global.Button, {
                onClick: props.bookTestDrive,
                text: props.translations.vdpBookTestDrive,
                applyStyle: 'primary',
                styleOverride: function styleOverride() {
                  return 'width: 100%;';
                },
                buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
                optionalCTAButtons: props.optionalCTAButtons,
                'data-cy': 'book-test-drive-button'
              })
            ),
            props.featureFlags.reserve && _react2.default.createElement(
              'div',
              { style: { width: '49%' } },
              _react2.default.createElement(_Global.Button, {
                'data-cy': 'reserve-vehicle-button',
                onClick: props.reserveThisVehicle,
                styleOverride: function styleOverride() {
                  return 'width: 100%;';
                },
                text: props.translations.vdpReserveThisVehicle,
                applyStyle: 'primary',
                buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle
              })
            )
          ),
          _react2.default.createElement(ContactButtons, {
            buttonSecondaryTheme: props.buttonSecondaryTheme,
            makeAnEnquiry: props.config.enquiryFormRedirect ? props.makeAnEnquiryExternal : props.makeAnEnquiry,
            iconColour: props.iconColour,
            showContactbuttons: props.showContactbuttons,
            showContactButton: props.showContactButton,
            showOnDemand: props.showOnDemand,
            onDemandBtn: props.onDemandBtn,
            makeATestDriveEnquiry: props.config.testDriveFormRedirect ? props.makeATestDriveEnquiryExternal : props.makeATestDriveEnquiry,
            makeAnOnDemandEnquiry: props.makeAnOnDemandEnquiry,
            makeAReserveEnquiry: props.config.reserveFormRedirect ? props.makeAReserveEnquiryExternal : props.makeAReserveEnquiry,
            makeAnInsuranceEnquiry: props.config.insuranceFormRedirect && props.makeAnInsuranceEnquiry,
            translations: props.translations,
            vehicleInfo: props.vehicleInfo,
            buttonStyles: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
            direction: props.globalStyling.direction,
            showReserveButton: props.config.showReserveButton,
            showTestDriveButton: props.config.showTestDriveButton
          })
        )
      )
    ),
    !props.showDeal && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(BottomInfoSection, {
        showButton: showButton,
        price: price,
        buttonSecondaryTheme: props.buttonSecondaryTheme,
        makeAnEnquiry: props.makeAnEnquiry,
        makeAnOnDemandEnquiry: props.makeAnOnDemandEnquiry,
        iconColour: props.iconColour,
        translations: props.translations,
        vehicleInfo: props.vehicleInfo,
        approvedImage: props.approvedImage,
        galleryWidth: props.galleryWidth,
        buttonStyle: props.globalStyling.uiElements.primaryButton && props.globalStyling.uiElements.primaryButton.buttonStyle,
        formattedDate: formattedDate,
        availabilityFont: props.config.availabilityFont,
        isRemarketingSite: props.featureFlags.isRemarketingSite,
        showFinance: props.config.showFinance
      }),
      props.translations.vdpGalleryAndMyDealBottomText && _react2.default.createElement(
        BottomText,
        {
          bottomTextFont: props.bottomTextFont,
          width: props.galleryWidth,
          showBorders: true
        },
        props.translations.vdpGalleryAndMyDealBottomText
      )
    )
  );
}

GalleryAndMyDeal.defaultProps = {
  iconColour: '#9E1B32',
  showDeal: true,
  subHeaderType: 'VehicleDescription'
};