'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleFeatures = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding-top: 30px;\n  '], ['\n    padding-top: 30px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 22px;\n'], ['\n    font-size: 22px;\n']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n     flex-direction: row;\n  '], ['\n     flex-direction: row;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 16px;\n    width: 30%;\n  '], ['\n    padding: 0 16px;\n    width: 30%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useCheckIsMobileScreen = require('../../../../../dist/hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _theme = require('../../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _Global = require('../../../Global');

var _types = require('../types');

var _utils = require('./utils/utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DESKTOP_KEY_FEATURE_NUMBER = 12;
var MOBILE_KEY_FEATURE_NUMBER = 7;
var NUMBER_COLUMNS = 3;

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__Container',
  componentId: 'sc-1ptv4z9-0'
})(['display:flex;flex-direction:column;padding-top:100px;', ';'], _theme2.default.max.large(_templateObject));

var Header = (0, _styledComponents2.default)(_Global.HeadingTwo).withConfig({
  displayName: 'VehicleFeatures__Header',
  componentId: 'sc-1ptv4z9-1'
})(['font-size:32px;letter-spacing:2px;text-transform:uppercase;width:100%;padding-bottom:10px;text-align:center;display:inline-block;padding-bottom:50px;', ';'], _theme2.default.max.large(_templateObject2));

var ItemsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__ItemsContainer',
  componentId: 'sc-1ptv4z9-2'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.large(_templateObject3));

var Item = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__Item',
  componentId: 'sc-1ptv4z9-3'
})(['display:flex;min-height:40px;height:auto;align-items:center;gap:10px;border-bottom:1px solid #151515;padding-top:10px;font-size:14px;line-height:20px;letter-spacing:0.5px;color:#151515;font-weight:300;']);

var ItemColumn = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleFeatures__ItemColumn',
  componentId: 'sc-1ptv4z9-4'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.large(_templateObject4));

var createItemColumn = function createItemColumn(items) {
  return _react2.default.createElement(
    ItemColumn,
    null,
    items.map(function (name, index) {
      return _react2.default.createElement(
        Item,
        { key: 'additional-feature-' + index.toString() },
        name
      );
    })
  );
};

var VehicleFeatures = function VehicleFeatures(_ref) {
  var translations = _ref.translations,
      vehicle = _ref.vehicle;
  var _vehicle$otherEquipme = vehicle.otherEquipment,
      otherEquipment = _vehicle$otherEquipme === undefined ? [] : _vehicle$otherEquipme;

  var heroFeatures = otherEquipment.find(function (e) {
    return e.category === 'Hero Features';
  });
  var heroFeaturesItems = heroFeatures && heroFeatures.equipment;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();

  var KEY_FEATURE_NUMBER = isMobile ? MOBILE_KEY_FEATURE_NUMBER : DESKTOP_KEY_FEATURE_NUMBER;

  var keyFeatures = heroFeaturesItems && heroFeaturesItems.length > 0 ? heroFeaturesItems.length >= KEY_FEATURE_NUMBER ? heroFeaturesItems.slice(0, KEY_FEATURE_NUMBER) : heroFeaturesItems : [];

  return _react2.default.createElement(
    _react.Fragment,
    null,
    heroFeaturesItems && heroFeaturesItems.length > 0 && _react2.default.createElement(
      Container,
      null,
      translations.vdpDesignTitle && _react2.default.createElement(
        Header,
        null,
        translations.vdpDesignTitle
      ),
      isMobile && _react2.default.createElement(
        _react.Fragment,
        null,
        keyFeatures.map(function (name, index) {
          return _react2.default.createElement(
            Item,
            { key: 'key-feature-' + index.toString() },
            name
          );
        })
      ),
      !isMobile && _react2.default.createElement(
        ItemsContainer,
        null,
        (0, _utils.chunkArrayByNumber)(keyFeatures, NUMBER_COLUMNS).map(function (items) {
          return createItemColumn(items);
        })
      )
    )
  );
};

exports.VehicleFeatures = VehicleFeatures;