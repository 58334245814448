'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VehicleGeneralInformation = undefined;

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding-top: 0;\n  '], ['\n    padding-top: 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    > * {\n      &:first-child {\n        color: #151515;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 500;\n        letter-spacing: 0.5px;\n        line-height: 24px;\n      }\n    }\n  '], ['\n    > * {\n      &:first-child {\n        color: #151515;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 500;\n        letter-spacing: 0.5px;\n        line-height: 24px;\n      }\n    }\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _AppState = require('../../../../types/AppState');

var _vehicleTypes = require('../../../../types/RollsRoyce/vehicleTypes');

var _Global = require('../../../Global');

var _StyledIcon = require('../../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _Button = require('../../Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _types = require('../types');

var _VdpUserActions = require('./VdpUserActions');

var _VdpUserActions2 = _interopRequireDefault(_VdpUserActions);

var _VdpVehiclePrice = require('./VdpVehiclePrice');

var _VdpVehiclePrice2 = _interopRequireDefault(_VdpVehiclePrice);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HIGHLIGHTS_NUMBER_ROWS = 6;
var MEDIUM_FONT = 'RivieraNights-Medium,Jost-Medium';
var FILTERED_CATEGORY_KEY = 'Hero Features';

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__Wrapper',
  componentId: 'sc-5ymjss-0'
})(['width:100%;', ''], function (_ref) {
  var darkMode = _ref.darkMode;
  return darkMode && '&&& * {color: #fff !important;}';
});

var ButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__ButtonsWrapper',
  componentId: 'sc-5ymjss-1'
})(['align-items:center;display:flex;flex-direction:column;justify-content:center;width:100%;padding-top:6px;', ';'], _RollsRoyce2.default.min.medium(_templateObject));

var HighlightCellLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__HighlightCellLabel',
  componentId: 'sc-5ymjss-2'
})(['color:#151515;font-size:14px;']);

var HighlightsDataRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__HighlightsDataRow',
  componentId: 'sc-5ymjss-3'
})(['align-items:flex-start;color:#151515;display:flex;flex-direction:row;font-size:14px;font-weight:300;letter-spacing:0.5px;line-height:22px;text-align:left;']);

var HighlightsLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__HighlightsLabel',
  componentId: 'sc-5ymjss-4'
})(['min-width:100px;']);

var HighlightsValue = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__HighlightsValue',
  componentId: 'sc-5ymjss-5'
})(['color:#151515;padding-left:5px;']);

var InfoSection = (0, _styledComponents2.default)(_Global.Div).withConfig({
  displayName: 'VehicleGeneralInformation__InfoSection',
  componentId: 'sc-5ymjss-6'
})(['padding-top:14px;width:100%;> *{&:first-child{color:#151515;font-size:14px;font-weight:500;letter-spacing:0.5px;line-height:24px;text-align:left;text-transform:uppercase;}}', ';'], _RollsRoyce2.default.max.medium(_templateObject2));

var MoreDetails = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'VehicleGeneralInformation__MoreDetails',
  componentId: 'sc-5ymjss-7'
})(['min-height:40px;min-width:300px;']);

var primaryButtonStyle = function primaryButtonStyle() {
  return '\n  &:hover,\n  &:active {\n    background-color: #502387;\n  }\n  width: auto;\n';
};

var RetailerInfoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__RetailerInfoContainer',
  componentId: 'sc-5ymjss-8'
})(['align-items:center;display:flex;flex-direction:column;']);

var RetailerInfoRow = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__RetailerInfoRow',
  componentId: 'sc-5ymjss-9'
})(['align-items:center;display:flex;flex-direction:row;font-family:\'RivieraNights-Medium\';font-weight:normal;margin-top:14px;text-transform:uppercase;']);

var RetailerPhoneText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__RetailerPhoneText',
  componentId: 'sc-5ymjss-10'
})(['font-size:14px;padding-left:10px;letter-spacing:2px;font-weight:normal;line-height:normal;']);

var RetailerText = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__RetailerText',
  componentId: 'sc-5ymjss-11'
})(['font-size:12px;font-family:\'RivieraNights-Bold\';letter-spacing:2px;font-weight:normal;text-align:center;line-height:normal;']);

var HighLight = function HighLight(_ref2) {
  var label = _ref2.label,
      value = _ref2.value;
  return _react2.default.createElement(
    HighlightsDataRow,
    null,
    _react2.default.createElement(
      HighlightsLabel,
      null,
      label + ': '
    ),
    _react2.default.createElement(
      HighlightsValue,
      null,
      value
    )
  );
};

var Line = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleGeneralInformation__Line',
  componentId: 'sc-5ymjss-12'
})(['display:', ';padding-top:14px;margin-bottom:14px;border-bottom:1px solid ', ';margin:0;width:100%;'], function (_ref3) {
  var visible = _ref3.visible;
  return visible === true ? 'block' : 'none';
}, function (_ref4) {
  var darkMode = _ref4.darkMode;
  return darkMode ? '#FFF' : '#3b3b3b';
});

var VehicleGeneralInformation = function VehicleGeneralInformation(_ref5) {
  var vehicle = _ref5.vehicle,
      translations = _ref5.translations,
      config = _ref5.config,
      globalStyling = _ref5.globalStyling,
      makeAnEnquiry = _ref5.makeAnEnquiry,
      printVehiclePdf = _ref5.printVehiclePdf,
      printingState = _ref5.printingState,
      darkMode = _ref5.darkMode,
      isEnquiry = _ref5.isEnquiry;

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global;
  }),
      socialShare = _useSelector.socialShare;

  var showExteriorStyles = vehicle.specification && (vehicle.specification.exterior || vehicle.specification.secondaryExterior);

  var showInteriorStyles = vehicle.specification && (vehicle.specification.interior || vehicle.specification.secondaryInterior || vehicle.specification.veneer);

  var _vehicle$specificatio = vehicle.specification,
      _vehicle$specificatio2 = _vehicle$specificatio.exterior,
      exterior = _vehicle$specificatio2 === undefined ? null : _vehicle$specificatio2,
      _vehicle$specificatio3 = _vehicle$specificatio.secondaryExterior,
      secondaryExterior = _vehicle$specificatio3 === undefined ? null : _vehicle$specificatio3,
      _vehicle$specificatio4 = _vehicle$specificatio.interior,
      interior = _vehicle$specificatio4 === undefined ? null : _vehicle$specificatio4,
      _vehicle$specificatio5 = _vehicle$specificatio.secondaryInterior,
      secondaryInterior = _vehicle$specificatio5 === undefined ? null : _vehicle$specificatio5,
      _vehicle$specificatio6 = _vehicle$specificatio.veneer,
      veneer = _vehicle$specificatio6 === undefined ? null : _vehicle$specificatio6;


  var retailerInformation = vehicle.retailerInformation && (vehicle.retailerInformation.group || vehicle.retailerInformation.phone);

  var _ref6 = vehicle || {},
      _ref6$otherEquipment = _ref6.otherEquipment,
      otherEquipment = _ref6$otherEquipment === undefined ? [] : _ref6$otherEquipment;

  var highlightedFeatures = otherEquipment.find(function (item) {
    return item.category === FILTERED_CATEGORY_KEY;
  });

  var _vehicle$retailerInfo = vehicle.retailerInformation,
      _vehicle$retailerInfo2 = _vehicle$retailerInfo.group,
      dealerGroupName = _vehicle$retailerInfo2 === undefined ? '' : _vehicle$retailerInfo2,
      dealerTelephone = _vehicle$retailerInfo.phone,
      _vehicle$retailerInfo3 = _vehicle$retailerInfo.name,
      dealerName = _vehicle$retailerInfo3 === undefined ? '' : _vehicle$retailerInfo3;


  return _react2.default.createElement(
    Wrapper,
    { darkMode: darkMode },
    showExteriorStyles && _react2.default.createElement(
      InfoSection,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)({
              typeface: {
                value: MEDIUM_FONT
              }
            }) + ' \n          ';
          }
        },
        translations.exteriorStyle
      ),
      exterior && _react2.default.createElement(HighLight, { label: translations.primary, value: exterior }),
      secondaryExterior && _react2.default.createElement(HighLight, {
        label: translations.secondary,
        value: secondaryExterior
      }),
      _react2.default.createElement(Line, { visible: true, darkMode: darkMode })
    ),
    showInteriorStyles && _react2.default.createElement(
      InfoSection,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)({
              typeface: {
                value: MEDIUM_FONT
              }
            }) + '\n          ';
          }
        },
        translations.interiorStyle
      ),
      interior && _react2.default.createElement(HighLight, { label: translations.primary, value: interior }),
      secondaryInterior && _react2.default.createElement(HighLight, {
        label: translations.secondary,
        value: secondaryInterior
      }),
      veneer && _react2.default.createElement(HighLight, { label: translations.veneer, value: veneer }),
      _react2.default.createElement(Line, { visible: true, darkMode: darkMode })
    ),
    highlightedFeatures && _react2.default.createElement(
      InfoSection,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)({
              typeface: {
                value: MEDIUM_FONT
              }
            }) + ' \n                  ';
          }
        },
        translations.highlightedFeatures
      ),
      highlightedFeatures.equipment.slice(0, HIGHLIGHTS_NUMBER_ROWS).map(function (feature, index) {
        return _react2.default.createElement(
          HighlightsDataRow,
          { key: index + '-' + feature },
          _react2.default.createElement(
            HighlightCellLabel,
            null,
            feature
          )
        );
      }),
      _react2.default.createElement(Line, { visible: true, darkMode: darkMode })
    ),
    _react2.default.createElement(
      InfoSection,
      null,
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return (0, _Global.fontStyleOverride)({
              typeface: {
                value: MEDIUM_FONT
              }
            }) + ' \n          ';
          }
        },
        translations.pricing
      ),
      _react2.default.createElement(_VdpVehiclePrice2.default, {
        vehicleInfo: vehicle,
        translations: translations,
        config: config,
        handleZeroPriceAsText: TransformStreamDefaultController,
        darkMode: darkMode,
        isEnquiry: isEnquiry
      }),
      _react2.default.createElement(Line, { visible: true, darkMode: darkMode })
    ),
    _react2.default.createElement(
      _Global.MobileOnly,
      null,
      printingState && makeAnEnquiry && printVehiclePdf && _react2.default.createElement(_VdpUserActions2.default, {
        config: config,
        globalStyling: globalStyling,
        translations: translations,
        makeATestDriveRequest: function makeATestDriveRequest() {},
        makeAReservation: function makeAReservation() {},
        makeAnEnquiry: makeAnEnquiry,
        vehicleInfo: vehicle,
        socialShare: socialShare,
        printingState: printingState,
        printVehiclePdf: printVehiclePdf
      })
    ),
    _react2.default.createElement(
      InfoSection,
      null,
      makeAnEnquiry && _react2.default.createElement(
        ButtonsWrapper,
        null,
        _react2.default.createElement(MoreDetails, {
          variant: 'primary',
          text: translations.enquire,
          onClick: function onClick(e) {
            makeAnEnquiry(e, vehicle);
          },
          styleOverride: primaryButtonStyle,
          icon: 'https://res.cloudinary.com/motortrak/v1706726136/locator/rolls-royce/global/button-arrow.svg'
        })
      ),
      retailerInformation && _react2.default.createElement(
        RetailerInfoContainer,
        null,
        dealerName && _react2.default.createElement(
          RetailerInfoRow,
          {
            style: {
              alignItems: 'flex-start',
              marginTop: makeAnEnquiry ? '14px' : '0'
            }
          },
          _react2.default.createElement(
            RetailerText,
            null,
            _react2.default.createElement(_StyledIcon2.default, {
              link: config.pinIcon,
              width: 12,
              height: 14,
              darkMode: darkMode,
              styleOverride: 'margin: -2px; margin-right: 5px;'
            }),
            dealerName
          )
        ),
        dealerGroupName && _react2.default.createElement(
          _Global.Link,
          {
            href: 'tel:' + dealerTelephone,
            styleOverride: function styleOverride() {
              return (0, _Global.fontStyleOverride)({
                typeface: {
                  value: MEDIUM_FONT
                }
              }) + '\n                 text-decoration: none;\n             ';
            }
          },
          _react2.default.createElement(
            RetailerInfoRow,
            null,
            _react2.default.createElement(_StyledIcon2.default, {
              link: config.phoneIconUrl,
              width: 12,
              height: 13,
              darkMode: darkMode
            }),
            _react2.default.createElement(
              RetailerPhoneText,
              null,
              dealerTelephone
            )
          )
        )
      )
    )
  );
};

exports.VehicleGeneralInformation = VehicleGeneralInformation;